import React from "react";
import {Props as ViewProps, View} from "./View";
import {Link} from "react-router-dom";
import {Color} from "./Enums/Color";
import {Text} from "./Text";
import {Destination} from "../../Utilities/Destination";

interface Props extends ViewProps {
    t?: string,
    destination: string | Destination
    fit?: boolean
}

export const NavigationLink: React.FC<Props> = (props) => {

    const style: React.CSSProperties = {
        color: Color.tint,
        textDecoration: "none",
        width: props.fit ? undefined : "inherit",
        maxWidth: "100%",
        WebkitTapHighlightColor: "transparent",
        transition: "opacity 0.1s ease-out",
        ...props.style
    }

    return (
        <Link to={(props.destination as Destination).route ?? props.destination} state={(props.destination as Destination).state} style={style}>
            <View {...props}>
                {props.t ? <Text t={props.t} /> : null}
                {props.children}
            </View>
        </Link>
    )
}
